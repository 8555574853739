import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";

function PaymentNotification() {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user);
    const subscribeDaysLeft = useSelector((state) => state.auth.subscribeDaysLeft);
    const textDays = {
        1: t('payment_notification.days.one'),
        2: t('payment_notification.days.two'),
        3: t('payment_notification.days.two'),
        4: t('payment_notification.days.two'),
        5: t('payment_notification.days.five'),
    }

    return user && user.company && subscribeDaysLeft < 5 && subscribeDaysLeft > 0 ?
        (
            <div className="payment-notification">
                Термін дії вашого акаунту закінчується через {subscribeDaysLeft} {textDays[subscribeDaysLeft]}. Не забудьте здійснити
                <a href="/cabinet/payment">ПЕРЕДПЛАТУ</a>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <Trans i18nKey="payment_notification.before" values={{ subscribeDaysLeft, textDays: textDays[subscribeDaysLeft] }} components={{ a: <a href="/cabinet/payment" /> }} />
            </div>
        )
        : subscribeDaysLeft < 0 ? (
            <div className="payment-notification">
                <Trans i18nKey="payment_notification.after" components={{ Link: <Link to="/cabinet/payment" /> }} />
            </div>
        ) : null;


}

export default PaymentNotification;
