import Api from './Api'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getPlans() {
        const token = localStorage.getItem('adminToken');
        return Api().get('/plans/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getPublicPlans() {
        return Api().get('/plans/public')
    },
    updatePlan({price, locationPrice}) {
        const token = localStorage.getItem('adminToken');
        return Api().put('/plans/1', {price, location_price: locationPrice}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
}