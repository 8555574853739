import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BoardMainTemplate from '../../components/Board/Templates/boardMainTemplate';
import { fetchBorderCompany } from '../../store/boardSlice';
import ConnectionStatus from '../../components/ConnectionStatus';
import {useTranslation} from "react-i18next";

function BoardPage() {
    const [orders, setOrders] = useState([]);
    const [ordersDone, setOrdersDone] = useState([]);
    const [connectionStatus, setConnectionStatus] = useState(true);
    const [eventSource, setEventSource] = useState(null);

    const token = localStorage.getItem('boardToken');
    const dispatch = useDispatch();
    const boardCompany = useSelector((state) => state.board.boardCompany);
    const startOrders = useSelector((state) => state.board.orders);
    const {  i18n } = useTranslation();

    const initializeEventSource = () => {
        if (!token) {
            window.location = '/board/auth';
            return;
        }

        const newEventSource = new EventSource(`${process.env.REACT_APP_BASE_URL}/order/test/1?token=${token}`);

        newEventSource.addEventListener('message', handleSSE);
        newEventSource.addEventListener('open', () => {
            setConnectionStatus(true);
            console.log('Connection opened');
        });

        newEventSource.addEventListener('error', () => {
            setConnectionStatus(false);
            console.log('Connection error');
        });

        setEventSource(newEventSource);
    };

    const handleSSE = (event) => {
        try {
            const result = JSON.parse(event.data);
            if (result.unsubscribe) {
                localStorage.removeItem('boardToken');
                window.location = '/board/auth';
            } else {
                setOrders(result.orders.orders);
                setOrdersDone(result.orders.ordersDone);
            }
        } catch (error) {
            console.error('Error parsing SSE data:', error);
        }
    };

    useEffect(() => {
        initializeEventSource();

        const handleOnline = () => {
            window.location.reload();
        };

        const handleOffline = () => {
            if (eventSource) {
                eventSource.close();
            }
            setConnectionStatus(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            if (eventSource) {
                eventSource.close();
            }
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (startOrders !== null) {
            setOrders(startOrders.orders);
            setOrdersDone(startOrders.ordersDone);
        }
    }, [startOrders]);

    useEffect(() => {
        dispatch(fetchBorderCompany())
            .unwrap().then((company) => {
            i18n.changeLanguage(company.user[0].lang);
        })
            .catch((error) => {
                if (error.message === 'Request failed with status code 401') {
                    localStorage.removeItem('boardToken');
                    window.location = '/board/auth';
                }
            });
    }, [dispatch, i18n]);

    return (
        <div className="board">
            {boardCompany !== null ? (
                <BoardMainTemplate
                    font_size={+boardCompany.font_size}
                    orders={orders}
                    ordersDone={ordersDone}
                    template={+boardCompany.template}
                    ready_column={boardCompany.ready_column}
                    in_progress_column={boardCompany.in_progress_column}
                />
            ) : null}
            <div className="board__connection">
                <ConnectionStatus active={connectionStatus} />
            </div>
        </div>
    );
}

export default BoardPage;
