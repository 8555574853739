import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import BaseInput from "../../components/Main/BaseInput";
import BaseButton from "../../components/Main/BaseButton";
import {useDispatch, useSelector} from "react-redux";
import {fetchUpdateCompanyConfiguration} from "../../store/companySlice";
import {fetchGetProfile} from "../../store/authSlice";
import BaseSelect from "../../components/Main/BaseSelect";
import {useTranslation} from "react-i18next";



function ConfigurationPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user)
    const [inProgressColumn, setInProgressColumn] = useState('');
    const [readyColumn, setReadyColumn] = useState('');
    const [readyDelay, setReadyDelay] = useState('');
    const [timeValidation, setTimeValidation] = useState(false);
    const [service, setService] = useState('');
    const [print, setPrint] = useState('');
    const fontSizes = [
        {value: 77, text: t('cabinet.configurations.sizes.small')},
        {value: 91, text: t('cabinet.configurations.sizes.medium')},
        {value: 117, text: t('cabinet.configurations.sizes.large')},
        {value: 151, text: t('cabinet.configurations.sizes.extra_large')},
    ]
    const [fontSize, setFontSize] = useState(fontSizes[0]);

    useEffect(() => {
        setInProgressColumn(user.company.in_progress_column);
        setReadyColumn(user.company.ready_column);
        setReadyDelay(user.company.ready_delay);
        setService(user.company.service);
        setPrint(user.company.print);
        setFontSize(fontSizes.find(item => item.value === +user.company.font_size));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTimeValidation(false)
    }, [readyDelay])

    const updateConfigurations = () => {
        if (readyDelay < 1) {
            setTimeValidation(true);
            return;
        }
        // eslint-disable-next-line no-restricted-globals
        top.postMessage({hideSpinner: false}, '*')
        dispatch(fetchUpdateCompanyConfiguration({
            config: {
                ready_delay: readyDelay,
                ready_column: readyColumn,
                in_progress_column: inProgressColumn,
                service: service,
                print: print,
                font_size: fontSize.value
            }
        })).unwrap().then(() => {
            dispatch(fetchGetProfile()).unwrap().finally(() => {
                // eslint-disable-next-line no-restricted-globals
                top.postMessage({hideSpinner: true}, '*')
            });
        }).finally(() => {
            // eslint-disable-next-line no-restricted-globals
            top.postMessage({hideSpinner: true}, '*')
        });
    }

    return (
        <div className="page">
            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="configuration">
                            <div className="configuration__body">
                                <div className="configuration__body__settings">
                                    <div className="configuration__body__settings__setting">
                                        <div className="configuration__body__settings__setting__label">
                                            {t('cabinet.configurations.columns.left')}
                                        </div>
                                        <div className="configuration__body__settings__setting__field">
                                            <BaseInput placeholder={t('cabinet.configurations.columns.left_placeholder')} value={inProgressColumn} maxLength={25}
                                                       onInput={(event) => {
                                                           setInProgressColumn(event.target.value)
                                                       }}/>
                                        </div>
                                    </div>
                                    <div className="configuration__body__settings__setting">
                                        <div className="configuration__body__settings__setting__label">
                                            {t('cabinet.configurations.columns.right')}
                                        </div>
                                        <div className="configuration__body__settings__setting__field">
                                            <BaseInput placeholder={t('cabinet.configurations.columns.right_placeholder')} value={readyColumn} maxLength={25}
                                                       onInput={(event) => {
                                                           setReadyColumn(event.target.value)
                                                       }}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="configuration__body__time">
                                    <div className="configuration__body__time__label">
                                        {t('cabinet.configurations.number')}
                                    </div>
                                    <div className="configuration__body__time__field">
                                        <BaseInput error={timeValidation} min={1} type="number" placeholder="10"
                                                   value={readyDelay} onInput={(event) => {
                                            setReadyDelay(event.target.value)
                                        }}/>
                                    </div>
                                    <div className="configuration__body__time__additional">{t('cabinet.configurations.minutes')}</div>
                                </div>
                                <div className="configuration__body__font-size">
                                    <div className="configuration__body__font-size__title">
                                        {t('cabinet.configurations.font_size')}
                                    </div>
                                    <div className="configuration__body__font-size__field">
                                        <BaseSelect defaultValue={fontSize} options={fontSizes} onChange={(value) => {
                                            setFontSize(value)
                                        }}/>
                                    </div>
                                </div>
                                <div className="configuration__body__services">
                                    <div className="configuration__body__services__title">
                                        {t('cabinet.configurations.select_service.title')}
                                    </div>
                                    <div className="configuration__body__services__list">
                                        <div
                                            className={`configuration__body__services__list__item`}
                                        >
                                            <input type="radio" id="poster" name="service"
                                                   checked={service === 'poster'} onChange={() => {
                                                setService('poster')
                                            }}/>
                                            <label htmlFor="poster">{t('cabinet.configurations.select_service.poster')}</label>
                                        </div>
                                        <div className="configuration__body__services__list__item">
                                            <input type="radio" id="kitchenkit" name="service"
                                                   checked={service === 'kitchenKit'} onChange={() => {
                                                setService('kitchenKit')
                                            }}/>
                                            <label htmlFor="kitchenkit">Kitchen Kit</label>
                                        </div>
                                        <div
                                            className={`configuration__body__services__list__item ${!user.company.plan.withCashier ? 'configuration__body__services__list__item__disabled' : ''}`}>
                                            <input type="radio" id="cashRegister" name="service"
                                                   disabled={!user.company.plan.withCashier}
                                                   checked={service === 'cashRegister'} onChange={() => {
                                                setService('cashRegister')
                                            }}/>
                                            <label htmlFor="cashRegister">{t('cabinet.configurations.select_service.cash_register')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="configuration__body__services">
                                    <div className="configuration__body__services__title">
                                        {t('cabinet.configurations.print.title')}
                                    </div>
                                    <div className="configuration__body__services__list">
                                        <div
                                            className={`configuration__body__services__list__item`}
                                        >
                                            <input type="radio" id="small" name="print"
                                                   checked={print === 'small'} onChange={() => {
                                                setPrint('small')
                                            }}/>
                                            <label htmlFor="small">{t('cabinet.configurations.print.small')}</label>
                                        </div>
                                        <div className="configuration__body__services__list__item">
                                            <input type="radio" id="big" name="print"
                                                   checked={print === 'big'} onChange={() => {
                                                setPrint('big')
                                            }}/>
                                            <label htmlFor="big">{t('cabinet.configurations.print.large')}</label>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="configuration__body__line"></div>*/}
                                {/*<div className="configuration__body__title">*/}
                                {/*    Як підключити табло замовлень?*/}
                                {/*</div>*/}
                                {/*<ol className="configuration__body__list">*/}
                                {/*    <li>Підключіть додатковий монітор по HDML кабелю або за допомогою Smart (TV) приставки</li>*/}
                                {/*    <li>У додатковому вікні браузера перейдіть за посиланням <a href="https://surl.li/ohtof">https://surl.li/ohtof</a></li>*/}
                                {/*    <li>На сторінці <b>“Синхронізація табло”</b> ви зможете знайти авторизаційні дані (Код авторизації та ID закладу)</li>*/}
                                {/*    <li>Введіть авторизаційні дані закладу, табло замовлень якого ви хочете підключити у відповідні комірки</li>*/}
                                {/*    <li>Готово</li>*/}
                                {/*</ol>*/}
                                {/*<div className="configuration__body__description">*/}
                                {/*    Відповідну інструкцію і відео ви зможете знайти за посиланням: <br/>*/}
                                {/*    <a href="https://www.dineintime.org/instruction" target={'_blank'} rel="noreferrer">https://www.dineintime.org/instruction</a>*/}
                                {/*</div>*/}
                            </div>
                            <div className="configuration__footer">
                                <div className="configuration__footer__btn">
                                    <div onClick={updateConfigurations}>
                                        <BaseButton
                                            disabled={user.company.ready_delay === readyDelay && user.company.ready_column === readyColumn && user.company.in_progress_column === inProgressColumn && user.company.service === service && user.company.print === print && +user.company.font_size === fontSize.value}
                                            text={t('cabinet.configurations.save')}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfigurationPage;
