import axios from "axios";

const api = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/`,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});
console.log(process.env)

api.interceptors.request.use((config) => {

    const token = localStorage.getItem("token");
    console.log(token , 'token')
    if (token) {
        config.headers.Authorization = "Bearer " + token;
    } else delete config.headers.Authorization;
    return config;
});

api.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        if (!!error.response && !!error.response.status) {
            // eslint-disable-next-line default-case
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token");
                    localStorage.removeItem('adminToken')
                    //window.location.href = "/";
            }
            return Promise.reject(error);
        }
    }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return api;
};
