import React from 'react';
import changeOrderNum from '../../../helpers/changeOrderNum';
import {useTranslation} from "react-i18next";

function DefaultTemplate({orders, ordersDone, in_progress_column, ready_column, font_size, more, moreDone}) {
    const { t } = useTranslation();
    return (
        <div className="templates">
            <div className="templates__block" >
                <div className="templates__block__item">
                    <div className="templates__block__item__title">
                        <span>{in_progress_column}</span>
                    </div>
                    <div className="templates__block__item__numbers"  id="numbers">
                        {
                            orders.map((order, index) => {
                                return <div style={{fontSize: `${((font_size) / 1200) * 100}vw`, lineHeight: `${(font_size / 1280) * 93}vw`, width: `calc(${(((font_size) * 2 - 20) / 1200) * 100}vw + 0.78125vw)`}} className="templates__block__item__numbers__number" key={order}>{changeOrderNum(order)}</div>
                            })
                        }
                        {
                            more > 0 ? (
                                <div className="templates__more">
                                    <span>{t('cabinet.templates_more_numbers', {more})}</span>
                                </div>
                            ) : null
                        }
                        {/*<div className="templates__block__item__numbers__number">255</div>*/}
                    </div>
                </div>
                <div className="templates__block__line"></div>
                <div className="templates__block__item">
                    <div className="templates__block__item__title templates__block__item__title-done">
                        <span>{ready_column}</span>
                    </div>
                    <div className="templates__block__item__numbers">
                        {
                            ordersDone.map((order, index) => {
                                return <div style={{fontSize: `${(font_size / 1280) * 100}vw`, lineHeight: `${(font_size / 1280) * 93}vw`, width: `calc(${((font_size * 2 + 20) / 1280) * 100}vw + 0.78125vw)`}} className="templates__block__item__numbers__number templates__block__item__numbers__number-done" key={order}>{changeOrderNum(order)}</div>
                            })
                        }
                        {
                            moreDone > 0 ? (
                                <div className="templates__more">
                                    <span>{t('cabinet.templates_more_numbers', {more: moreDone})}</span>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DefaultTemplate;
