import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import BoardService from "../services/BoardService";

const initialState = {
    boardToken: null,
    boardCompany: null,
    orders: null,
    expiredSubscription: false,
}

export const fetchBoardLogin = createAsyncThunk(
    'board/fetchBoardLogin',
    async function ({code, id}) {
        const { data } = await BoardService.auth({
            code, id
        })
        return data;
    }
)

export const fetchCashierReadyOrder = createAsyncThunk(
    'board/fetchCashierReadyOrder',
    async function ({id}) {
        const { data } = await BoardService.sendCashierReadyOrder(id)
        return data;
    }
)

export const fetchCashierDeleteOrder = createAsyncThunk(
    'board/fetchCashierDeleteOrder',
    async function ({id}) {
        const { data } = await BoardService.sendCashierDeleteOrder(id)
        return data;
    }
)

export const fetchOrders = createAsyncThunk(
    'board/fetchOrders',
    async function () {
        const { data } = await BoardService.getOrders()
        return data;
    }
)

export const fetchBorderCompany = createAsyncThunk(
    'board/fetchBorderCompany',
    async function () {
        const { data } = await BoardService.getBorderCompany()
        return data;
    }
)

export const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchBoardLogin.fulfilled]: (state, action) => {
            if(action.payload.status === 'error') {
                state.expiredSubscription = true;
                return;
            }
            state.boardToken = action.payload.access_token;
            localStorage.setItem("boardToken", action.payload.access_token);
            state.boardCompany = action.payload.company;
        },
        [fetchOrders.fulfilled]: (state, action) => {
            state.orders = action.payload;
        },
        [fetchBorderCompany.fulfilled]: (state, action) => {
            state.boardCompany = action.payload;
        },
    }
})

export const { board } = boardSlice.actions

export default boardSlice.reducer