import React, { useEffect, useState } from 'react';
import Logo from '../../assets/images/fullLogo.svg';
import CashierCard from "../../components/Main/cashier/CashierCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchBorderCompany } from "../../store/boardSlice";
import { useTranslation } from "react-i18next";
import ConnectionStatus from "../../components/ConnectionStatus";

function CashierPage() {
    const { t } = useTranslation();
    const [orders, setOrders] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState(true);
    const [eventSource, setEventSource] = useState(null);

    const token = localStorage.getItem('boardToken');
    const dispatch = useDispatch();
    const startOrders = useSelector((state) => state.board.orders);
    const {  i18n } = useTranslation();

    const initializeEventSource = () => {
        if (!token) {
            window.location = '/cashier/auth';
            return;
        }

        const newEventSource = new EventSource(`${process.env.REACT_APP_BASE_URL}/order/cashier/1?token=${token}`);

        newEventSource.addEventListener('message', handleSSE);
        newEventSource.addEventListener('open', () => {
            setConnectionStatus(true);
            console.log('Connection opened');
        });

        newEventSource.addEventListener('error', () => {
            setConnectionStatus(false);
            console.log('Connection error');
        });

        setEventSource(newEventSource);
    };

    const handleSSE = (event) => {
        try {
            const result = JSON.parse(event.data);
            if (result.unsubscribe) {
                localStorage.setItem('cashierLogin', 'false');
                window.location = '/cashier/auth';
            } else {
                setOrders(result.orders);
            }
        } catch (error) {
            console.error('Error parsing SSE data:', error);
        }
    };

    useEffect(() => {
        initializeEventSource();

        const handleOnline = () => {
            window.location.reload();
        };

        const handleOffline = () => {
            if (eventSource) {
                eventSource.close();
            }
            setConnectionStatus(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            if (eventSource) {
                eventSource.close();
            }
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if (startOrders !== null) {
            setOrders(startOrders.orders);
        }
    }, [startOrders]);

    useEffect(() => {
        dispatch(fetchBorderCompany()).unwrap().then((company) => {
            i18n.changeLanguage(company.user[0].lang);
            setIsLoad(true);
        }).catch((error) => {
            if (error.message === "Request failed with status code 401") {
                localStorage.removeItem('boardToken');
                window.location = '/cashier/auth';
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return isLoad ? (
        <div className="cashier">
            <div className="cashier__head">
                <h1 className="cashier__head__title">{t('cashier.title')}</h1>
                <img src={Logo} alt="logo" className="cashier__head__logo" />
            </div>
            <hr className="cashier__line" />
            <div className="cashier__body">
                {
                    orders.map((order) =>
                        <CashierCard key={order.id} data={order} />
                    )
                }
            </div>
            <span className="cashier__connection">
                <ConnectionStatus active={connectionStatus} />
            </span>
        </div>
    ) : null;
}

export default CashierPage;
