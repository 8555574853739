import Api from './Api'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    updateCompanyTemplate(data) {
        return Api().post('/companies/template', data)
    },
    updateActiveLocations(data) {
        return Api().post('/companies/locations', data)
    },
    getPayForm(data) {
        return Api().post('/payment', data)
    },
    changeTariff(data) {
        return Api().post('/companies/change-tariff', data)
    },
    updateCompanyConfiguration(data) {
        console.log(data, 'data')
        return Api().post('/companies/configuration', data)
    },
    confirmPrivacyPolicy() {
        return Api().post('/companies/confirmPrivacyPolicy')
    },
    getCompanies() {
        const token = localStorage.getItem('adminToken');
        return Api().get('/companies', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    updateCompaniesPrices(data) {
        const token = localStorage.getItem('adminToken');
        return Api().post('/companies/prices', {companies: data}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    updateCompaniesDiscountPercent(data) {
        const token = localStorage.getItem('adminToken');
        return Api().post('/companies/discount-percent', {companies: data}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    updateCompaniesPlanInfo(data) {
        const token = localStorage.getItem('adminToken');
        return Api().post('/companies/plan-info', {companies: data}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
}