import React, {useEffect, useState} from 'react';
import Logo from '../../assets/images/fullLogo.svg';
import KeyImage from '../../assets/images/fluent_key.svg';
import BaseInput from "../../components/Main/BaseInput";
import BaseButton from "../../components/Main/BaseButton";
import { useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchBoardLogin} from "../../store/boardSlice";
import {useTranslation} from "react-i18next";
import LanguageSelect from "../../components/landing/LanguageSelect";


function AuthBoardPage({isCashier = false}) {
    const { t } = useTranslation();
    const expiredSubscription = useSelector((state) => state.board.expiredSubscription)
    const boardToken = useSelector((state) => state.board.boardToken)
    const boardCompany = useSelector((state) => state.board.boardCompany)
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [code, setCode] = useState('');
    const [id, setId] = useState('');
    const [showServiceError, setShowServiceError] = useState(false)
    const dispatch = useDispatch();

    const onAuth = () => {
        //navigate("/board");
        dispatch(fetchBoardLogin({code, id})).unwrap().catch(() => {
            if(expiredSubscription) return;
            setError(true);
        });
    }

    useEffect(() => {
        if(localStorage.getItem('boardToken')) {
            if(isCashier){
                if(localStorage.getItem('cashierLogin') === 'true'){
                    navigate("/cashier");
                }
            } else {
                navigate("/board");
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(boardToken) {
            if(isCashier){
                if(boardCompany.service === 'cashRegister'){
                    localStorage.setItem('cashierLogin', 'true');
                    navigate("/cashier");
                } else {
                    setShowServiceError(true);
                }
            } else {
                navigate("/board");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardToken]);

    return (
        <div className="list">
            <div className="list__logo">
                <img src={Logo} alt="logo" className="list__logo__image"/>
            </div>
            <div className="list__body">
                <div className="list__body__block">
                    <div className="list__body__block__title">{t('board.auth.title')}</div>
                    <span className="list__body__block__language">
                            <LanguageSelect design={'green'} />
                    </span>
                    <div className="list__body__block__field">
                        <div className="list__body__block__field__label">{t('board.auth.code')}</div>
                        <div className="list__body__block__field__input">
                            <BaseInput placeholder={t('board.auth.input_code')} icon={KeyImage} onInput={(event) => {
                                setCode(event.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className="list__body__block__field list__body__block__field-mt">
                        <div className="list__body__block__field__label">{t('board.auth.id')}</div>
                        <div className="list__body__block__field__input">
                            <BaseInput placeholder={t('board.auth.input_id')} icon={KeyImage} onInput={(event) => {
                                setId(event.target.value)
                            }}/>
                        </div>
                        <div
                            className={`list__body__block__field__error ${error ? 'list__body__block__field__error-show' : ''}`}>
                            {t('board.auth.errors.auth')}
                        </div>
                        <div
                            className={`list__body__block__field__error ${showServiceError ? 'list__body__block__field__error-show' : ''}`}>
                            {t('board.auth.errors.cashier')}
                        </div>
                    </div>
                    <div className="list__body__block__btn">
                        <span onClick={() => {
                            onAuth()
                        }}><BaseButton text={t('board.auth.btn')} onClick/></span>
                    </div>
                </div>
            </div>
            <div className={`list__notification ${expiredSubscription ? 'list__notification-show' : ''}`}>
            <div className="list__notification__title">{t('board.auth.subscription.title')}</div>
                <div className="list__notification__text">{t('board.auth.subscription.text')}</div>
                <div className="list__notification__finish">{t('board.auth.subscription.finish')}</div>
            </div>
        </div>
    );
}

export default AuthBoardPage;
