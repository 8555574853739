import { configureStore } from '@reduxjs/toolkit'
import authReducer from './authSlice'
import companyReducer from './companySlice'
import boardReducer from './boardSlice'
import planReducer from './plansSlice'
export const store = configureStore({
    reducer: {
        auth: authReducer,
        company: companyReducer,
        board: boardReducer,
        plans: planReducer,
    },
})
