import React from 'react';
import ArrowIcon from '../../assets/images/arrow.svg';
function Accordion({text, title}) {
    const [active, setActive] = React.useState(false);

    return (
        <>
            <div className="accordion">
                <div className="accordion__head" onClick={() => {setActive(!active)}}>
                    <div className="accordion__head__title">
                        {title}
                    </div>
                    <div className={`accordion__head__arrow ${active ? '' : 'accordion__head__arrow-active'}`}>
                        <img src={ArrowIcon} alt="arrow"/>
                    </div>
                </div>
                <div className={`accordion__body ${active ? 'accordion__body-show' : ''}`}>
                    <div className="accordion__body__text" dangerouslySetInnerHTML={{__html: text}}></div>
                </div>
            </div>
        </>
    );
}

export default Accordion;
