import React, {useEffect} from 'react';
import SolarShop from '../assets/images/solar_shop.svg';
import Laptop from '../assets/images/home/laptop.png';
import IPad from '../assets/images/home/ipad.png';
import FullLogo from '../assets/images/fullLogo.svg';
import People from '../assets/images/home/people.svg';
import Connect from '../assets/images/home/connect.svg';
import Queue from '../assets/images/home/queue.svg';
import Money from '../assets/images/home/money.svg';
import PayMoney from '../assets/images/home/pay-money.svg';
import FoodBankImage from '../assets/images/food-bank.svg';
import ScreenImage from '../assets/images/screen.svg';
import FluentImage from '../assets/images/fluent.svg';
import {useDispatch, useSelector} from "react-redux";
import {fetchGetPublicPlan} from "../store/plansSlice";
import { useTranslation } from 'react-i18next';
import Footer from "../components/landing/Footer";
import currencyConverter from "../helpers/currencyConverter";
import currencyTranslate from "../helpers/currencyTranslate";
import CurrencySelect from "../components/landing/CurrencySelect";


function HomePage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const plans = useSelector((state) => state.plans.publicPlans);
    const [minPrice, setMinPrice] = React.useState('');
    const currency = useSelector((state) => state.plans.currency);

    useEffect(() => {
        dispatch(fetchGetPublicPlan())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (plans){
            setMinPrice(plans.map(item => item.price).sort((a, b) => a.price - b.price)[0]);
        }
    }, [plans]);

    return (
        <div className='home-page'>
            <div className="home-page__head">
                <div className="home-page__head__content">
                    <div className="home-page__head__content__line"></div>
                    <div className="home-page__head__content__logo">
                        <img src={FullLogo} alt="logo"/>
                    </div>
                    <div className="home-page__head__content__title">
                        {t('home-page.head.title')}
                    </div>
                    <a href="https://joinposter.com/ua/applications/dineintime" className="home-page__head__content__btn">
                        {t('home-page.head.btn')}
                        <img src={SolarShop} alt="shop"/>
                    </a>
                    <div className="home-page__head__content__images">
                        <img className="home-page__head__content__images__ipad" src={IPad} alt="iPad"/>
                        <img className="home-page__head__content__images__laptop" src={Laptop} alt="Laptop"/>
                    </div>
                </div>
            </div>
            <div className="home-page__list">
                <div className="home-page__list__content">
                    <div className="home-page__list__content__items">
                        <div className="home-page__list__content__items__item">
                            <img src={People} alt="people" className="home-page__list__content__items__item__img"/>
                            <div className="home-page__list__content__items__item__text">
                                {t('home-page.list.people')}
                            </div>
                        </div>
                        <div className="home-page__list__content__items__item">
                            <img src={Connect} alt="connect" className="home-page__list__content__items__item__img"/>
                            <div className="home-page__list__content__items__item__text">
                                {t('home-page.list.connect')}
                            </div>
                        </div>
                        <div className="home-page__list__content__items__item">
                            <img src={Queue} alt="queue" className="home-page__list__content__items__item__img"/>
                            <div className="home-page__list__content__items__item__text">
                                {t('home-page.list.queue')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-page__description">
                {t('home-page.description')}
            </div>
            <div className="home-page__pay">
                <div className="home-page__pay__content">
                    <ul className="home-page__pay__content__list">
                        <li className="home-page__pay__content__list__item">
                            {t('home-page.pay.one')}
                        </li>
                        <li className="home-page__pay__content__list__item">
                            {t('home-page.pay.two')}
                        </li>
                        <li className="home-page__pay__content__list__item">
                            {t('home-page.pay.three')}
                        </li>
                        {/*<li className="home-page__pay__content__list__item">*/}
                        {/*    Зручна оплата*/}
                        {/*    <img src={Mastercard} alt="mastercard"*/}
                        {/*         className="home-page__pay__content__list__item__img"/>*/}
                        {/*    <img src={Visa} alt="visa" className="home-page__pay__content__list__item__img"/>*/}
                        {/*    <img src={P24} alt="p24" className="home-page__pay__content__list__item__img"/>*/}
                        {/*</li>*/}
                    </ul>
                    <div className="home-page__pay__content__price">
                        <img src={Money} alt="money" className="home-page__pay__content__price__icon"/>
                        <div className="home-page__pay__content__price__text">
                            {t('from')} <span className="home-page__pay__content__price__text__sum">{currencyConverter(minPrice, currency)}</span> {currencyTranslate(currency)}
                        </div>
                    </div>
                </div>
                <img src={PayMoney} alt="Money" className="home-page__pay__icon"/>
            </div>
            {
                plans ? (
                    <div className="home-page__plans">
                        <div className="home-page__plans__title">
                            {t('home-page.plans.title')}
                        </div>
                        <span className="home-page__plans__currency">
                            <CurrencySelect design="green"/>
                        </span>
                        <div className="home-page__plans__content">
                            {
                                [...plans].sort((a, b) => a.price - b.price).sort((a, b) => a.withCashier - b.withCashier).map((item, index) => (
                                    <div
                                        className={`home-page__plans__content__item ${item.withCashier ? 'home-page__plans__content__item__cashier' : ''}`}
                                        key={index}>
                                        <div className="home-page__plans__content__item__head">
                                            <img src={FoodBankImage} alt="location"
                                                 className="home-page__plans__content__item__head__location"/>
                                            <div className="home-page__plans__content__item__head__title">
                                                {item.name.replace('+', '')}
                                            </div>
                                            {
                                                    currency ? (
                                                    <div className="home-page__plans__content__item__head__price">
                                                        <div
                                                            className="home-page__plans__content__item__head__price__sum">
                                                            {currencyConverter(item.price, currency)}
                                                        </div>
                                                        <div
                                                            className="home-page__plans__content__item__head__price__text">
                                                            {currencyTranslate(currency)}
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div className="home-page__plans__content__item__body">
                                            <img src={ScreenImage} alt="table"
                                                 className="home-page__plans__content__item__body__icon"/>
                                            <div className="home-page__plans__content__item__body__text">
                                                {t('home-page.plans.tariff_description', {count: item.location_count})}

                                            </div>
                                        </div>
                                        {
                                            item.withCashier ? (
                                                <div className="home-page__plans__content__item__body">
                                                    <img src={FluentImage} alt="table"
                                                         className="home-page__plans__content__item__body__icon"/>
                                                    <div className="home-page__plans__content__item__body__text">
                                                        {t('home-page.plans.cashier')}
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                ) : null
            }
            <Footer />
        </div>
    );
}

export default HomePage;
