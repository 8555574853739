import AppRouter from './router/index'; // Импортируем роутер
import { store } from './store/index'
import { Provider } from 'react-redux'
import './i18n';
import './App.css';
import './style/index.scss'

function App() {
  return (
      <Provider store={store}>
          <div className="App">
              <AppRouter />
          </div>
      </Provider>
  );
}

export default App;
