import React from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import {useTranslation} from "react-i18next";

function ContactsPage() {
    const { t } = useTranslation();

    return (
        <div className="page">
            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="contacts">
                            <div className="contacts__tile">
                                {t('cabinet.contacts.title')}
                            </div>
                            <div className="contacts__body">
                                <div className="contacts__body__item">
                                    <div className="contacts__body__item__icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <path d="M2.00391 7.75C2.00391 6.75544 2.39899 5.80161 3.10226 5.09835C3.80552 4.39509 4.75934 4 5.75391 4H22.2499C23.2445 4 24.1983 4.39509 24.9016 5.09835C25.6048 5.80161 25.9999 6.75544 25.9999 7.75V7.76L13.9999 13.907L2.00391 7.764V7.75ZM2.00391 9.449V19.25C2.00391 20.2446 2.39899 21.1984 3.10226 21.9017C3.80552 22.6049 4.75934 23 5.75391 23H22.2499C23.2445 23 24.1983 22.6049 24.9016 21.9017C25.6048 21.1984 25.9999 20.2446 25.9999 19.25V9.446L14.3419 15.418C14.2361 15.4722 14.1188 15.5005 13.9999 15.5005C13.881 15.5005 13.7638 15.4722 13.6579 15.418L2.00391 9.449Z" fill="#518C70"/>
                                        </svg>
                                    </div>
                                    <a href="mailto:support@dineintime.org" target="_blank"  className="contacts__body__item__text" style={{color: '#518C70'}} rel="noreferrer">
                                        support@dineintime.org
                                    </a>
                                </div>
                                <div className="contacts__body__item">
                                    <div className="contacts__body__item__icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 0C8.81812 0 5.76375 1.26506 3.51562 3.51469C1.26518 5.76521 0.000623381 8.81734 0 12C0 15.1813 1.26562 18.2357 3.51562 20.4853C5.76375 22.7349 8.81812 24 12 24C15.1819 24 18.2362 22.7349 20.4844 20.4853C22.7344 18.2357 24 15.1813 24 12C24 8.81869 22.7344 5.76431 20.4844 3.51469C18.2362 1.26506 15.1819 0 12 0Z" fill="url(#paint0_linear_510_279)"/>
                                            <path d="M5.43184 11.8733C8.93059 10.3493 11.2631 9.34448 12.4293 8.85904C15.7631 7.47285 16.455 7.2321 16.9068 7.22395C17.0062 7.22235 17.2275 7.24692 17.3718 7.36364C17.4918 7.46207 17.5256 7.5952 17.5425 7.68867C17.5575 7.78204 17.5781 7.99485 17.5612 8.16098C17.3812 10.0585 16.5993 14.6631 16.2018 16.7884C16.035 17.6877 15.7031 17.9892 15.3825 18.0186C14.685 18.0827 14.1562 17.5581 13.4812 17.1158C12.4256 16.4234 11.8293 15.9925 10.8037 15.3169C9.61872 14.5362 10.3875 14.107 11.0625 13.4057C11.2387 13.2222 14.31 10.4294 14.3681 10.176C14.3756 10.1444 14.3831 10.0262 14.3118 9.96398C14.2425 9.90154 14.1393 9.92292 14.0643 9.93979C13.9575 9.96379 12.2718 11.079 9.00184 13.2854C8.52372 13.6142 8.09059 13.7745 7.70059 13.7661C7.27309 13.7569 6.44809 13.5239 5.83497 13.3247C5.08497 13.0804 4.48684 12.9512 4.53934 12.5363C4.56559 12.3203 4.86372 12.0992 5.43184 11.8733Z" fill="white"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_510_279" x1="1200" y1="0" x2="1200" y2="2400" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#2AABEE"/>
                                                    <stop offset="1" stop-color="#229ED9"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                    <a href='https://t.me/dineintime' target="_blank" className="contacts__body__item__text" style={{color: '#2AABEE'}} rel="noreferrer">
                                        @dineintime
                                    </a>
                                </div>
                                <div className="contacts__body__item">
                                    <div className="contacts__body__item__icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M4 21V9L12 3L20 9V21H14V14H10V21H4Z" fill="#422757"/>
                                        </svg>
                                    </div>
                                    <a href="https://www.dineintime.org/" target="_blank" style={{color: '#422757'}} className="contacts__body__item__text" rel="noreferrer">
                                        https://www.dineintime.org
                                    </a>
                                </div>
                            </div>
                            <div className="contacts__background">
                                <div className="contacts__background__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="158" height="189" viewBox="0 0 158 189" fill="none">
                                        <path d="M138.25 130.448C138.25 94.3058 110.205 69.75 79.0003 69.75C48.1245 69.75 19.7503 93.7792 19.7503 130.843C15.8003 133.082 13.167 137.295 13.167 142.167V155.333C13.167 162.575 19.092 168.5 26.3337 168.5H32.917V128.342C32.917 102.864 53.5228 82.2583 79.0003 82.2583C104.478 82.2583 125.084 102.864 125.084 128.342V175.083H72.417V188.25H125.084C132.325 188.25 138.25 182.325 138.25 175.083V167.052C142.134 165.011 144.834 160.995 144.834 156.255V141.113C144.834 136.505 142.134 132.489 138.25 130.448Z" fill="#D4CED8"/>
                                        <path d="M59.2503 142.167C62.8862 142.167 65.8337 139.219 65.8337 135.583C65.8337 131.947 62.8862 129 59.2503 129C55.6145 129 52.667 131.947 52.667 135.583C52.667 139.219 55.6145 142.167 59.2503 142.167Z" fill="#D4CED8"/>
                                        <path d="M98.7503 142.167C102.386 142.167 105.334 139.219 105.334 135.583C105.334 131.947 102.386 129 98.7503 129C95.1145 129 92.167 131.947 92.167 135.583C92.167 139.219 95.1145 142.167 98.7503 142.167Z" fill="#D4CED8"/>
                                        <path d="M118.5 122.614C116.93 113.359 112.136 104.957 104.967 98.8969C97.7982 92.8364 88.7162 89.5077 79.3288 89.5C59.3813 89.5 37.9196 106.024 39.6313 131.962C47.7494 128.64 54.9194 123.361 60.5019 116.596C66.0845 109.83 69.9062 101.788 71.6263 93.1867C80.2504 110.501 97.9596 122.417 118.5 122.614Z" fill="#D4CED8"/>
                                    </svg>
                                </div>
                                <div className="contacts__background__text">
                                    {t('cabinet.contacts.description')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactsPage;
