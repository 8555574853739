export default function currencyConverter(price, language) {
    const currencies = [
        {
            lang: 'UAH',
            rate: 36,
        },
        {
            lang: 'USD',
            rate: 1,
        },
        {
            lang: 'KTZ',
            rate: 600,
        },
        {
            lang: 'EUR',
            rate: 1,
        },
    ]
    let lang = currencies.find(item => item.lang === language);
    if(!lang) {
        lang = "USD"
    } else {
        lang = lang.lang
    }
    const result = (currencies.find(item => item.lang === lang).rate * price)
    console.log(result, ' - ', result % 1 === 0)
    return result % 1 === 0 ? result : result.toFixed(2);
}
