import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import ProfileService from "../services/ProfileService";
import moment from "moment/moment";

const initialState = {
    token: null,
    user: null,
    subscribeDaysLeft: null,
    confirmPrivacyPolicy: true,
}

export const fetchAuth = createAsyncThunk(
    'auth/fetchAuth',
    async function ({id}) {
        const { data } = await ProfileService.auth({
            code: id
        })
        return data;
    }
)

export const fetchAuthAdmin = createAsyncThunk(
    'auth/fetchAuthAdmin',
    async function ({login, password}) {
        const { data } = await ProfileService.authAdmin({
            login, password
        })
        return data;
    }
)

export const changeConfirmPrivacyPolicy = createAsyncThunk(
    'auth/changeConfirmPrivacyPolicy',
    async function () {
        console.log('changeConfirmPrivacyPolicy')
        return true;
    }
)

export const fetchGetProfile = createAsyncThunk(
    'auth/fetchGetProfile',
    async function () {
        const { data } = await ProfileService.profile();
        return data;
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // auth: async (state, action) => {
        //     const { data } = await ProfileService.auth({
        //         code: action.payload
        //     })
        //     state.token = data.access_token;
        //     localStorage.setItem("token", data.access_token);
        //     state.user = data.user;
        // },
    },
    extraReducers: {
        [fetchAuth.fulfilled]: (state, action) => {
            state.token = action.payload.access_token;
            localStorage.setItem("token", action.payload.access_token);
            if(action.payload.user.company.expireSubscribe) {
                state.subscribeDaysLeft = moment(action.payload.user.company.expireSubscribe).diff(moment(), 'days')
            }
            state.confirmPrivacyPolicy = action.payload.user.company.confirmPrivacyPolicy;
            state.user = action.payload.user;
        },
        [fetchGetProfile.fulfilled]: (state, action) => {
            state.token = localStorage.getItem("token");
            if(action.payload.company.expireSubscribe) {
                state.subscribeDaysLeft = moment(action.payload.company.expireSubscribe).diff(moment(), 'days')
            }
            state.confirmPrivacyPolicy = action.payload.company.confirmPrivacyPolicy;
            state.user = action.payload;
        },
        [changeConfirmPrivacyPolicy.fulfilled]: (state, action) => {
            console.log('changeConfirmPrivacyPolicy', action.payload, 'action.payload')
            state.confirmPrivacyPolicy = true;
        },
    }
})

export const { auth } = authSlice.actions

export default authSlice.reducer
