import React, { useState} from 'react';
import LanguageImage from '../../assets/images/currency.svg';
import LanguageArrow from '../../assets/images/languageArrow.svg';
import {useDispatch, useSelector} from "react-redux";
import {fetchCurrency} from "../../store/plansSlice";

const CurrencySelect = ({design = 'white'}) => {
    const dispatch = useDispatch()
    const [showList, setShowList] = useState(false);
    const currencies = ['UAH', 'USD', 'KTZ', 'EUR'];
    const currency = useSelector((state) => state.plans.currency);


    const changeCurrency = (curr) => {
        setShowList(false)
        dispatch(fetchCurrency({curr}))
        localStorage.setItem('currency', curr);
    }

    return (
        <div className={`language_select language_select__${design} ${showList && design === 'green' ? 'language_select__open' : ''}`}>
            <div className="language_select__main">
                <div className="language_select__main__selected" onClick={() => {setShowList(!showList)}}>
                    <img src={LanguageImage} alt="Language"/>
                    <span className="language_select__main__selected__text">
                        {currency}
                    </span>
                    <img src={LanguageArrow} alt="Arrow" className={`language_select__main__selected__arrow ${showList ? 'language_select__main__selected__arrow-select' : ''}`}/>
                </div>
                {
                    showList && <div className="language_select__main__list">
                        {currencies.filter(item => item !== currency).map((curr, index) => (
                            <div className='language_select__main__list__item' key={index}
                                 onClick={() => changeCurrency(curr)}>
                                {curr}
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
};

export default CurrencySelect;
