import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import FoodBankImage from '../../assets/images/food-bank.svg';
import ScreenImage from '../../assets/images/screen.svg';
import MoneyImage from '../../assets/images/money.svg';
import CalendarImage from '../../assets/images/calendar.svg';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";
import {fetchChangePlan} from "../../store/companySlice";
import {fetchGetPlan} from "../../store/plansSlice";
import {fetchGetProfile} from "../../store/authSlice";
import {Trans, useTranslation} from "react-i18next";
import currencyConverter from "../../helpers/currencyConverter";
import currencyTranslate from "../../helpers/currencyTranslate";


function TariffsPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const plans = useSelector((state) => state.plans.plans);
    const [newTariff, setNewTariff] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const currency = useSelector((state) => state.plans.currency);

    useEffect(() => {
        dispatch(fetchGetPlan());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const changePlan = () => {
        setIsLoading(true);
        dispatch(fetchChangePlan({plan_key: newTariff.plan_key})).then(() => {
            dispatch(fetchGetProfile());
            setNewTariff(null);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <div className="page">
            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="tariffs">
                            <div className="tariffs__body">
                                <div className="tariffs__body__title">
                                    {t('cabinet.tariffs.title')}
                                </div>
                                <div className="tariffs__body__tariffs">
                                    {
                                        plans ? plans.map((plan, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={`tariffs__body__tariffs__item ${user.company.plan && user.company.plan.plan_key === plan.plan_key ? 'tariffs__body__tariffs__item-active' : ''}`}>
                                                    <div className="tariffs__body__tariffs__item__title">
                                                        <img src={FoodBankImage} alt="food"
                                                             className="tariffs__body__tariffs__item__title__img"/>
                                                        <div className="tariffs__body__tariffs__item__title__text">
                                                            {plan.name}
                                                        </div>
                                                    </div>
                                                    <div className="tariffs__body__tariffs__item__body">
                                                        <img src={ScreenImage} alt="screen"
                                                             className="tariffs__body__tariffs__item__body__img"/>
                                                        <div className="tariffs__body__tariffs__item__body__text">
                                                            <Trans i18nKey="cabinet.tariffs.location_count" values={{ count: plan.location_count }} components={{ b: <b /> }} />
                                                        </div>
                                                    </div>
                                                    <div className="tariffs__body__tariffs__item__footer">
                                                        <div className="tariffs__body__tariffs__item__footer__price">
                                                            <div
                                                                className="tariffs__body__tariffs__item__footer__price__summ">
                                                                {currencyConverter(plan.price, user.currencyCodeIso)}
                                                            </div>
                                                            <div
                                                                className="tariffs__body__tariffs__item__footer__price__currency">
                                                                {currencyTranslate(user.currencyCodeIso)}
                                                            </div>
                                                        </div>
                                                        <div className="tariffs__body__tariffs__item__footer__tariff">
                                                            {
                                                                user.company.plan && user.company.plan.plan_key === plan.plan_key ?
                                                                (
                                                                    <div className="tariffs__body__tariffs__item__footer__tariff__text">
                                                                        {t('cabinet.tariffs.your_tariff')}
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div
                                                                        className="tariffs__body__tariffs__item__footer__tariff__btn"
                                                                        onClick={() => {setNewTariff(plan)}}
                                                                    >
                                                                        {t('cabinet.tariffs.select_tariff')}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) : null
                                    }
                                </div>
                                <div className="tariffs__body__info">
                                    <div className="tariffs__body__info__item">
                                        <img src={MoneyImage} alt="money" className="tariffs__body__info__item__img"/>
                                        <div className="tariffs__body__info__item__text">
                                            {t('cabinet.tariffs.trial')}
                                        </div>
                                    </div>
                                    <div className="tariffs__body__info__item">
                                        <img src={CalendarImage} alt="money"
                                             className="tariffs__body__info__item__img"/>
                                        <div className="tariffs__body__info__item__text">
                                            {t('cabinet.tariffs.tariff_valid')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                moment(user.company.expireSubscribe).diff(moment(), 'days') > 0 &&
                                <div className="tariffs__footer">
                                    {t('cabinet.tariffs.subscribe')} {moment(user.company.expireSubscribe).format('DD.MM.YYYY')}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                newTariff ? (
                    <div className="tariffs__modal">
                        <div className="tariffs__modal__wrapper">
                            <div className="tariffs__modal__wrapper__modal">
                                <div className="tariffs__modal__wrapper__modal__title">
                                    {t('cabinet.tariffs.change_tariff')}
                                </div>

                                <div className="tariffs__modal__wrapper__modal__body">
                                    <div
                                        className="tariffs__body__tariffs__item">
                                        <div className="tariffs__body__tariffs__item__title">
                                            <img src={FoodBankImage} alt="food"
                                                 className="tariffs__body__tariffs__item__title__img"/>
                                            <div className="tariffs__body__tariffs__item__title__text">
                                                {newTariff.name}
                                            </div>
                                        </div>
                                        <div className="tariffs__body__tariffs__item__body">
                                            <img src={ScreenImage} alt="screen"
                                                 className="tariffs__body__tariffs__item__body__img"/>
                                            <div className="tariffs__body__tariffs__item__body__text">
                                                <Trans i18nKey="cabinet.tariffs.location_count" values={{ count: newTariff.location_count }} components={{ b: <b /> }} />
                                            </div>
                                        </div>
                                        <div className="tariffs__body__tariffs__item__footer">
                                            {
                                                currency ? (
                                                    <>
                                                        <div className="tariffs__body__tariffs__item__footer__price">
                                                            <div
                                                                className="tariffs__body__tariffs__item__footer__price__summ">
                                                                {currencyConverter(newTariff.price, currency)}
                                                            </div>
                                                            <div
                                                                className="tariffs__body__tariffs__item__footer__price__currency">
                                                                {currencyTranslate(currency)}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="tariffs__modal__wrapper__modal__footer">
                                    <div className="tariffs__modal__wrapper__modal__footer__cancel" onClick={() => {
                                        setNewTariff(null)
                                    }}>
                                        {t('cabinet.tariffs.cancel')}
                                    </div>
                                    <div
                                        className={`tariffs__modal__wrapper__modal__footer__approve ${isLoading ? 'tariffs__modal__wrapper__modal__footer__approve-disable' : '' }`} onClick={() => {changePlan()}}>
                                        {t('cabinet.tariffs.accept')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    );
}

export default TariffsPage;
