import React from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import {useSelector} from 'react-redux'
import moment from "moment";
import PolicyModal from "../../components/Board/policyModal";
import {useTranslation} from "react-i18next";



function InformationPage() {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user)
    const confirmPrivacyPolicy = useSelector((state) => state.auth.confirmPrivacyPolicy)
    return (
        <div className="page">
            {
                (!confirmPrivacyPolicy && false) ? <PolicyModal></PolicyModal> : null
            }

            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="information">
                            <div className="information__body">
                                <div className="information__body__head">
                                    <div className="information__body__head__item">
                                        <div className="information__body__head__item__name">Email:</div>
                                        <a href="mailto:client.123@gmail.com" className="information__body__head__item__value">{user.email}</a>
                                    </div>
                                    <div className="information__body__head__item">
                                        <div className="information__body__head__item__name">{t('cabinet.information.company_name')}:</div>
                                        <div className="information__body__head__item__value">{user.company.name}</div>
                                    </div>
                                </div>
                                <div className="information__body__title">
                                    {t('cabinet.information.all_establishment')}:
                                </div>
                                <div className="information__body__table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>{t('cabinet.information.establishment_name')}</th>
                                                <th>{t('cabinet.information.establishment_address')}</th>
                                                <th>ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            user.company.location.map((item, key) => (
                                                <tr key={key}>
                                                    <td>{item.spot_name}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.id}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {
                                moment(user.company.expireSubscribe).diff(moment(), 'days') > 0 &&
                                <div className="information__footer">
                                    <div className="information__footer__notification">
                                        <div className="information__footer__notification__message">{t('cabinet.information.subscribe')} {moment(user.company.expireSubscribe).format('DD.MM.YYYY')}</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InformationPage;
