import React from 'react';
function BaseToggle({checked, onChange, disabled = false}) {
    const rand = Math.ceil(Math.random()*1000000);

    const change = (e) => {
        if(!disabled){
            onChange(e.target.checked);
        }
    }
    return (
        <div className="base-toggle">
            <input type="checkbox" disabled={disabled} defaultChecked={checked} id={`base-toggle_${rand}`} onInput={(e) => {change(e)}} /><label htmlFor={`base-toggle_${rand}`}>Toggle</label>
        </div>
    );
}

export default BaseToggle;
