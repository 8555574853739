export default function changeOrderNum(orderNum) {
  // Если номер заказа оканчивается на 00
  if (orderNum % 100 === 0) {
    return '100';
  }

  // Если номер заказа меньше 100
  if (orderNum < 100) {
    // Форматирование, чтобы длина строки была 3 символа, добавляя ведущие нули
    return orderNum.toString().padStart(3, '0');
  }

  // Если номер заказа больше или равен 100
  // Берем последние две цифры и добавляем ведущий ноль
  const lastTwoDigits = orderNum % 100;
  return '0' + lastTwoDigits.toString().padStart(2, '0');
} // Path: src/helpers/changeOrderNum.js
