export default function currencyTranslate(language) {
    const currencies = [
        {
            lang: 'UAH',
            translate: 'грн\\міс.',
        },
        {
            lang: 'USD',
            translate: 'usd/month.',
        },
        {
            lang: 'KTZ',
            translate: 'тг\\мес.',
        },
        {
            lang: 'EUR',
            translate: 'eur/month.',
        },
    ]
    let lang = currencies.find(item => item.lang === language);
    if(!lang) {
        lang = "USD"
    } else {
        lang = lang.lang
    }
    return currencies.find(item => item.lang === lang).translate;
}
