import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthPage from "../pages/cabinet/auth";
import InformationPage from "../pages/cabinet/information";
import ConfigurationPage from "../pages/cabinet/configurations";
import SynchronizationPage from "../pages/cabinet/synchronization";
import TemplatesPage from "../pages/cabinet/templates";
import TariffsPage from "../pages/cabinet/tariffs";
import AuthBoardPage from "../pages/board/auth";
import BoardPage from "../pages/board";
import AdminList from "../pages/admin/list";
import HomePage from "../pages/homePage";
import FaqPage from "../pages/cabinet/faq";
import ContactsPage from "../pages/cabinet/contacts";
import PrivacyPolicy from "../pages/privacyPolicy";
import Instruction from "../pages/instruction";
import CashierPage from "../pages/cashier";
import axios from "axios";
import {fetchGetProfile} from "../store/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const AppRouter = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user)
    const {  i18n } = useTranslation();
    const [lang, setLang] = React.useState();

    useEffect(() => {
        if (window.location.pathname.match(/\/(cabinet|cashier|board)(\/.+)?/) && lang) {
            i18n.changeLanguage(lang);
        }

    }, [i18n, lang])

    useEffect(() => {
        if(localStorage.getItem('token') && window.location.pathname.match(/\/(cabinet|cashier|board)(\/.+)?/)) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
            dispatch(fetchGetProfile()).unwrap().then((data) => {

                setLang(data.lang)
                // eslint-disable-next-line no-restricted-globals
                top.postMessage({hideSpinner: true}, '*')
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Router>
            <Routes>]
                <Route path="/" element={<HomePage />} />
                <Route path="/cabinet/auth/:id" element={<AuthPage />} />
                {user && user.company ? (
                    <>
                        <Route path="/cabinet/information" element={<InformationPage />} />
                        <Route path="/cabinet/configurations" element={<ConfigurationPage />} />
                        <Route path="/cabinet/synchronization" element={<SynchronizationPage />} />
                        <Route path="/cabinet/templates" element={<TemplatesPage />} />
                        <Route path="/cabinet/tariffs" element={<TariffsPage />} />
                        <Route path="/cabinet/faq" element={<FaqPage />} />
                        <Route path="/cabinet/contacts" element={<ContactsPage />} />
                    </>
                ): null}

                <Route path="/board/auth" element={<AuthBoardPage />} />
                <Route path="/board" element={<BoardPage />} />
                <Route path="/cashier/auth" element={<AuthBoardPage isCashier={true} />} />
                <Route path="/cashier" element={<CashierPage />} />
                <Route path="/admin/list" element={<AdminList />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/instruction" element={<Instruction />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
