import Api from './Api'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    auth(data) {
        return Api().post('/auth/login', data)
    },
    profile() {
        return Api().get('/auth/profile')
    },
    authAdmin(data) {
        return Api().post('/auth/login/admin', data)
    }
}
