import React from 'react';
import FullLogo from '../assets/images/fullLogo.svg';

function Header() {
    return (
        <header className="header">
            <img src={FullLogo} alt="DineInTime" className="header__logo"/>
        </header>
    );
}

export default Header;
