import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import BaseButton from "../../components/Main/BaseButton";
import ProductImage from '../../assets/images/product.svg';
import DocumentImage from '../../assets/images/document.svg';
import ArrowLeftImage from '../../assets/images/arrow_left.svg';
import InfoImage from '../../assets/images/info.svg';
import {useDispatch, useSelector} from "react-redux";
import {fetchUpdateActiveLocations} from "../../store/companySlice";
import {Link} from "react-router-dom";
import moment from "moment";
import BaseToggle from "../../components/Main/BaseToggle";
import {fetchGetProfile} from "../../store/authSlice";
import {Trans, useTranslation} from "react-i18next";



function SynchronizationPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user)
    const subscribeDaysLeft = useSelector((state) => state.auth.subscribeDaysLeft);
    const [locations, setLocations] = useState([]);
    useEffect(() => {
        if(user && user.company && user.company.location){
            setLocations(user.company.location.map((item, index) => {
                return {
                    ...item,
                    checked: !!item.expireSubscribe
                }
            }));
        }
    }, [user]);

    const changeLocation = (checked, key) => {
        const newLocations = [...locations];
        newLocations[key].checked = checked;
        setLocations(newLocations);
        dispatch(fetchUpdateActiveLocations({locations : newLocations.filter(item => item.checked === true).map((item) => item.id)})).unwrap().then(() => {
            dispatch(fetchGetProfile());
        });
    }


    const getClass = (item) => {
        if(subscribeDaysLeft < 0 || moment(user.company.expireSubscribe).diff(moment(), 'days') < 0) {
            return '';
        }
        if(locations.find((location) => location.id === item.id && location.checked === true)){
            return 'synchronization__body__block-active';
        } else {
            if(locations.filter((location) => location.checked === true).length < user.company.location_count) {
                return 'synchronization__body__block-no-selected';
            }
        }
        return '';
    }
    return (
        <div className="page">
            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="synchronization">
                            <div className="synchronization__body">
                                <div className="synchronization__body__head">
                                    <div className="synchronization__body__head__tariff">
                                        <img src={DocumentImage} alt="Document"
                                             className="synchronization__body__head__tariff__img"/>
                                        <div className="synchronization__body__head__tariff__text">
                                            {t('cabinet.synchronization.your_tariff')}: <b> {user.company.plan ? user.company.plan.name : '-'} </b>
                                        </div>
                                    </div>
                                    <div className="synchronization__body__head__locations">
                                        <div className="synchronization__body__head__locations__text">
                                            {t('cabinet.synchronization.available_establishments')}: <b> {user.company.location_count} </b>
                                        </div>
                                    </div>
                                    <div className="synchronization__body__head__info">
                                        <Link to={'/cabinet/tariffs'}>
                                            {t('cabinet.synchronization.more_about_tariffs')}
                                            <img src={ArrowLeftImage} alt="Arrow"
                                                 className="synchronization__body__head__info__img"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="synchronization__body__head">
                                    <div className="synchronization__body__head__tariff-info">
                                        <img src={InfoImage} alt="info"
                                             className="synchronization__body__head__tariff-info__img"/>
                                        <div className="synchronization__body__head__tariff-info__text">
                                            {t('cabinet.synchronization.select_establishment')}:
                                        </div>
                                    </div>
                                </div>
                                {
                                    user.company.location.map((item, key) => (
                                        <div
                                            className={`synchronization__body__block ${getClass(item)}`}
                                            key={key}
                                        >
                                            <div className="synchronization__body__block__select">
                                                <BaseToggle
                                                    checked={locations[key]?.checked}
                                                    disabled={getClass(item) === ''}
                                                    onChange={(data) => {
                                                        changeLocation(data, key)
                                                    }}
                                                />
                                            </div>
                                            <div className="synchronization__body__block__line"></div>
                                            <img src={ProductImage} alt="product"
                                                 className="synchronization__body__block__img"/>
                                            <div className="synchronization__body__block__name">
                                                <div className="synchronization__body__block__name__value">
                                                    {item.spot_name}
                                                </div>
                                            </div>
                                            <div className="synchronization__body__block__line"></div>
                                            <table className="synchronization__body__block__main">
                                                <tr className="synchronization__body__block__main__row">
                                                    <td className="synchronization__body__block__main__text">
                                                        {t('cabinet.synchronization.auth_code')}:
                                                    </td>
                                                    <td className="synchronization__body__block__main__id">
                                                        {item.code}
                                                    </td>
                                                </tr>
                                                <tr className="synchronization__body__block__main__row">
                                                    <td className="synchronization__body__block__main__text">
                                                        {t('cabinet.synchronization.establishment_id')}:
                                                    </td>
                                                    <td className="synchronization__body__block__main__id">
                                                        {item.id}
                                                    </td>
                                                </tr>
                                            </table>
                                            <div className="synchronization__body__block__end">
                                                {
                                                    subscribeDaysLeft < 0 ?
                                                        <div className="synchronization__body__block__end__message">
                                                            <Trans i18nKey="cabinet.synchronization.pay" components={{ Link: <Link to="/cabinet/tariffs"/>, br: <br /> }} />
                                                        </div> :
                                                        <a href={'/board/auth'} target="_blank" rel="noreferrer">
                                                            <BaseButton
                                                                disabled={getClass(item) !== 'synchronization__body__block-active'}
                                                                text={t('cabinet.synchronization.open_board')}/>
                                                        </a>
                                                }

                                            </div>
                                        </div>))
                                }
                                {/*<div className="synchronization__body__block">*/}
                                {/*    <img src={ProductImage} alt="product" className="synchronization__body__block__img synchronization__body__block__img-disabled"/>*/}
                                {/*    <div className="synchronization__body__block__name">*/}
                                {/*        <div className="synchronization__body__block__name__value">*/}
                                {/*            Yoki Pizza*/}
                                {/*        </div>*/}
                                {/*        <div className="synchronization__body__block__name__id">*/}
                                {/*            018284*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="synchronization__body__block__line"></div>*/}
                                {/*    <div className="synchronization__body__block__main">*/}
                                {/*        <div className="synchronization__body__block__main__id">*/}
                                {/*            12312312*/}
                                {/*        </div>*/}
                                {/*        <div className="synchronization__body__block__main__copy synchronization__body__block__main__copy-disabled">*/}
                                {/*            СКОПІЮВАТИ КОД АВТОРИЗАЦІЇ*/}
                                {/*            <img src={CopyImage} alt="copy"/>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="synchronization__body__block__end">*/}
                                {/*        <div className="synchronization__body__block__end__message">*/}
                                {/*            Для роботи табло необхідно провести <a href="#">оплату</a>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div>
                                {
                                    user.company.service === 'cashRegister' ? (
                                        <div className="synchronization__footer">
                                            <Trans i18nKey="cabinet.synchronization.cashier_link" components={{ b: <b /> }} /> <a
                                            href={'https://dntb.in/cashier'} target="_blank"
                                            rel="noreferrer">https://dntb.in/c</a>
                                        </div>
                                    ) : null
                                }
                                <div className="synchronization__footer">
                                    <Trans i18nKey="cabinet.synchronization.board_link" components={{ b: <b /> }} /> <a
                                    href={'https://dntb.in'} target="_blank"
                                    rel="noreferrer">https://dntb.in/b</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SynchronizationPage;
