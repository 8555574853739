import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import PlanService from "../services/PlanService";

const initialState = {
    plans: null,
    publicPlans: null,
    currency: localStorage.getItem('currency') || 'UAH',
}

export const fetchGetPlan = createAsyncThunk(
    'plans/fetchGetPlan',
    async function () {
        const { data } = await PlanService.getPlans();
        return data;
    }
)

export const fetchCurrency = createAsyncThunk(
    'plans/fetchCurrency',
    async function ({curr}) {
        console.log('curr', curr)
        return curr;
    }
)

export const fetchGetPublicPlan = createAsyncThunk(
    'plans/fetchGetPublicPlan',
    async function () {
        const { data } = await PlanService.getPublicPlans();
        return data;
    }
)

export const fetchUpdatePlan = createAsyncThunk(
    'plans/fetchUpdatePlan',
    async function ({price, locationPrice}) {
        const { data } = await PlanService.updatePlan({price, locationPrice});
        return data;
    }
)

export const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchGetPlan.fulfilled]: (state, action) => {
            state.plans = action.payload;
        },
        [fetchGetPublicPlan.fulfilled]: (state, action) => {
            state.publicPlans = action.payload;
        },
        [fetchCurrency.fulfilled]: (state, action) => {
            state.currency = action.payload;
        },
    }
})

export const { plans } = plansSlice.actions

export default plansSlice.reducer
