import React, { useState} from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import BaseButton from "../../components/Main/BaseButton";
import TemplateOne from '../../assets/images/template1.png';
import TemplateTwo from '../../assets/images/template2.png';
import TemplateThree from '../../assets/images/template3.png';
import TemplateFour from '../../assets/images/template4.png';
import {useDispatch, useSelector} from "react-redux";
import {fetchUpdateCompanyTemplate} from "../../store/companySlice";
import {fetchGetProfile} from "../../store/authSlice";
import {useTranslation} from "react-i18next";



function TemplatesPage() {
    const { t } = useTranslation();
    const user = useSelector((state) => state.auth.user)
    const [active, setActive] = useState(null);
    const templatesData = [
        {
            id: 1,
            img: TemplateOne,
            show: true
        },
        {
            id: 2,
            img: TemplateTwo,
            show: true
        },
        {
            id: 3,
            img: TemplateThree,
            show: true
        },
        {
            id: 4,
            img: TemplateFour,
            show: user.poster_company_id === 'testas' || user.poster_company_id === 'appetito3'
        },
    ]
    const dispatch = useDispatch();
    const updateTemplate = () => {
        // eslint-disable-next-line no-restricted-globals
        top.postMessage({hideSpinner: false}, '*')
        dispatch(fetchUpdateCompanyTemplate({active})).unwrap().then(() => {
            dispatch(fetchGetProfile()).unwrap().finally(() => {
                // eslint-disable-next-line no-restricted-globals
                top.postMessage({hideSpinner: true}, '*')
            });
        }).finally(() => {
            // eslint-disable-next-line no-restricted-globals
            top.postMessage({hideSpinner: true}, '*')
        });
    }
    return (
        <div className="page">
            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="cabinet-templates">
                            <div className="cabinet-templates__body">
                                <div className="cabinet-templates__body__title">
                                    {t('cabinet.templates.title')}
                                </div>
                                <div className="cabinet-templates__body__blocks">
                                    {
                                        templatesData.filter(item => item.id === +user.company.template).map((item, index) => (
                                            <div className={`cabinet-templates__body__blocks__template`} key={index}>
                                                <img src={item.img} alt="template"/>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="cabinet-templates__body__title cabinet-templates__body__title-next">
                                    {t('cabinet.templates.alternative_templates')}
                                </div>
                                <div className="cabinet-templates__body__blocks">
                                    {
                                        templatesData.filter(item => item.show).filter(item => item.id !== +user.company.template).map((item, index) => (
                                            <div className={`cabinet-templates__body__blocks__template`} key={index} onClick={() => {setActive(item.id)}}>
                                                <img src={item.img} alt="template"/>
                                                {active === item.id ? (
                                                    <div className="cabinet-templates__body__blocks__block__active" >
                                                        {t('cabinet.templates.selected')}
                                                        {/*<img src={CheckImage} alt="check"/>*/}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>
                            <div className="cabinet-templates__footer">
                                <div onClick={() => {updateTemplate()}}><BaseButton text={t('cabinet.templates.save')} disabled={!!!active}/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplatesPage;
