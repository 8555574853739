import React from 'react';
import Sidebar from "../../components/Main/Sidebar";
import Header from "../../components/header";
import PaymentNotification from "../../components/Main/PaymentNotification";
import Accordion from "../../components/Main/Accordion";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";




function FaqPage() {
    const { t } = useTranslation();

    return (
        <div className="page">
            <Header/>
            <PaymentNotification/>
            <div className="container">
                <div className="container__body">
                    <div className="container__body__content">
                        <Sidebar/>
                        <div className="faq">
                            <div className="faq__title">{t('cabinet.faq.title')}</div>
                            <div className="faq__accordions">
                                {
                                    t('cabinet.faq.questions', {returnObjects: true}).map((item, index) => {
                                        return (
                                            <Accordion key={index} title={item.title} text={item.text}/>
                                        )
                                    })
                                }
                            </div>
                            <div className="faq__footer">
                                <div className="faq__footer__text">
                                    {t('cabinet.faq.more_questions')}
                                </div>
                                <Link to={'/cabinet/contacts'} className="faq__footer__link">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                        <path d="M7.29661 4.62046C7.25669 4.58327 7.22468 4.53842 7.20248 4.48858C7.18027 4.43875 7.16833 4.38496 7.16737 4.33041C7.16641 4.27586 7.17644 4.22168 7.19687 4.17109C7.21731 4.12051 7.24772 4.07456 7.2863 4.03598C7.32487 3.9974 7.37082 3.96699 7.42141 3.94656C7.47199 3.92613 7.52618 3.91609 7.58072 3.91706C7.63527 3.91802 7.68907 3.92996 7.7389 3.95216C7.78873 3.97436 7.83358 4.00638 7.87077 4.04629L10.0374 6.21296C10.1135 6.28913 10.1562 6.39239 10.1562 6.50004C10.1562 6.6077 10.1135 6.71095 10.0374 6.78713L7.87077 8.95379C7.83358 8.99371 7.78873 9.02572 7.7389 9.04792C7.68907 9.07013 7.63527 9.08207 7.58072 9.08303C7.52618 9.08399 7.47199 9.07396 7.42141 9.05353C7.37082 9.03309 7.32487 9.00268 7.2863 8.9641C7.24772 8.92553 7.21731 8.87958 7.19687 8.82899C7.17644 8.77841 7.16641 8.72422 7.16737 8.66968C7.16833 8.61513 7.18027 8.56133 7.20248 8.5115C7.22468 8.46167 7.25669 8.41682 7.29661 8.37963L8.76994 6.90629L3.52119 6.90629C3.41345 6.90629 3.31012 6.86349 3.23393 6.7873C3.15774 6.71112 3.11494 6.60779 3.11494 6.50004C3.11494 6.3923 3.15774 6.28897 3.23393 6.21278C3.31012 6.13659 3.41345 6.09379 3.52119 6.09379L8.76994 6.09379L7.29661 4.62046Z" fill="#422757"/>
                                    </svg>
                                        {t('cabinet.faq.contacts')}
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqPage;
