import React, {useEffect, useState} from 'react';
import LanguageImage from '../../assets/images/language.svg';
import LanguageArrow from '../../assets/images/languageArrow.svg';
import {useTranslation} from "react-i18next";

const LanguageSelect = ({design = 'white'}) => {
    const {  i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [showList, setShowList] = useState(false);
    const languages = ['ua', 'en', 'ru'];

    useEffect(() => {
        if(selectedLanguage){
            i18n.changeLanguage(selectedLanguage);
            setShowList(false)
        }
    }, [i18n, selectedLanguage])

    return (
        <div className={`language_select language_select__${design} ${showList && design === 'green' ? 'language_select__open' : ''}`}>
            <div className="language_select__main">
                <div className="language_select__main__selected" onClick={() => {setShowList(!showList)}}>
                    <img src={LanguageImage} alt="Language"/>
                    <span className="language_select__main__selected__text">
                        {selectedLanguage}
                    </span>
                    <img src={LanguageArrow} alt="Arrow" className={`language_select__main__selected__arrow ${showList ? 'language_select__main__selected__arrow-select' : ''}`}/>
                </div>
                {
                    showList && <div className="language_select__main__list">
                        {languages.filter(item => item !== selectedLanguage).map((lang, index) => (
                            <div className='language_select__main__list__item' key={index}
                                 onClick={() => setSelectedLanguage(lang)}>
                                {lang}
                            </div>
                        ))}
                    </div>
                }
            </div>


        </div>
    );
};

export default LanguageSelect;
