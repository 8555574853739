import React, {useState} from 'react';
import {Link} from "react-router-dom";
import ConfigurationImage from '../../assets/images/navs/configuration.svg';
import ContactsImage from '../../assets/images/navs/contacts.svg';
import FaqImage from '../../assets/images/navs/faq.svg';
import InformationImage from '../../assets/images/navs/information.svg';
import PaymentsImage from '../../assets/images/navs/payments.svg';
import SyncImage from '../../assets/images/navs/sync.svg';
import TemplatesImage from '../../assets/images/navs/templates.svg';
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";
function Sidebar() {
    const { t } = useTranslation();
    const location = useLocation();
    const [navs] = useState([
        {
            img: InformationImage,
            title: t('cabinet.sidebar.information'),
            path: '/cabinet/information'
        },
        {
            img: SyncImage,
            title: t('cabinet.sidebar.synchronization'),
            path: '/cabinet/synchronization'
        },
        {
            img: ConfigurationImage,
            title: t('cabinet.sidebar.configurations'),
            path: '/cabinet/configurations'
        },
        {
            img: TemplatesImage,
            title: t('cabinet.sidebar.templates'),
            path: '/cabinet/templates'
        },
        {
            img: PaymentsImage,
            title: t('cabinet.sidebar.tariffs'),
            path: '/cabinet/tariffs'
        },
        {
            img: FaqImage,
            title: t('cabinet.sidebar.faq'),
            path: '/cabinet/faq'
        },
        {
            img: ContactsImage,
            title: t('cabinet.sidebar.contacts'),
            path: '/cabinet/contacts'
        },
    ]);

    return (
        <div className="sidebar">
            <div className="sidebar__list">
                {navs.map((item, key) => (
                    <Link to={item.path} key={key} className={`sidebar__list__item ${location.pathname === item.path ? 'sidebar__list__item--active' : ''}`}>
                        <img src={item.img} alt={item.title} className="sidebar__list__item__image" />
                        <span className="sidebar__list__item__text">{item.title}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Sidebar;
