import React, {useEffect} from 'react';
import DefaultTemplate from "./defaultTemplate";
import BlackTemplate from "./blackTemplate";
import FullTemplate from "./fullTemplate";
import AppetitoTemplate from "./appetitoTemplate";

function BoarMainTemplate({orders, ordersDone, template, in_progress_column, ready_column, font_size}) {
    const [more, setMore] = React.useState(0);
    const [moreDone, setMoreDone] = React.useState(0);
    const [maxOrdersInBoard, setMaxOrdersInBoard] = React.useState(0);

    useEffect(() => {
        setTimeout(() => {
            if(!orders.length) {
                return;
            }
            const numbers = document.getElementById('numbers');
            const children = numbers.children[0];
            const childrenTotalHeight = children.offsetHeight + parseInt( window.getComputedStyle(children).marginTop) + parseInt(window.getComputedStyle(children).marginBottom);
            const childrenTotalWidth = children.offsetWidth + parseInt( window.getComputedStyle(children).marginLeft) + parseInt(window.getComputedStyle(children).marginRight);
            const countChildrenInColumn = Math.floor(numbers.getBoundingClientRect().height / childrenTotalHeight);
            const countChildrenInRow = Math.floor((numbers.getBoundingClientRect().width - 1 )  / childrenTotalWidth);
            setMore(orders.length - (countChildrenInColumn * countChildrenInRow));
            setMoreDone(ordersDone.length - (countChildrenInColumn * countChildrenInRow));
            setMaxOrdersInBoard(countChildrenInColumn * countChildrenInRow);
        }, 500)
    }, [orders.length, ordersDone.length]);

    const templates = [
        {
            id: 2,
            content: <DefaultTemplate
                font_size={font_size}
                more={more}
                orders={more > 0 ? orders.slice(0, orders.length - more) : orders}
                ordersDone={moreDone > 0 ? ordersDone.slice(0, ordersDone.length - moreDone) : ordersDone}
                in_progress_column={in_progress_column}
                ready_column={ready_column}
                moreDone={moreDone}
            />
        },
        {
            id: 1,
            content: <BlackTemplate
                font_size={font_size}
                more={more}
                orders={more > 0 ? orders.slice(0, orders.length - more) : orders}
                ordersDone={moreDone > 0 ? ordersDone.slice(0, ordersDone.length - moreDone) : ordersDone}
                in_progress_column={in_progress_column}
                ready_column={ready_column}
                moreDone={moreDone}
            />
        },
        {
            id: 3,
            content: <FullTemplate
                font_size={font_size}
                more={more}
                orders={more > 0 ? orders.slice(0, orders.length - more) : orders}
                ordersDone={moreDone > 0 ? ordersDone.slice(0, ordersDone.length - moreDone) : ordersDone}
                in_progress_column={in_progress_column}
                ready_column={ready_column}
                moreDone={moreDone}
            />
        },
        {
            id: 4,
            content: <AppetitoTemplate
                font_size={font_size}
                more={more}
                orders={more > 0 ? orders.slice(0, orders.length - more) : orders}
                ordersDone={moreDone > 0 ? ordersDone.slice(0, ordersDone.length - moreDone) : ordersDone}
                in_progress_column={in_progress_column}
                ready_column={ready_column}
                moreDone={moreDone}
                maxOrdersInBoard={maxOrdersInBoard}
            />
        }
    ]

    return (
        templates.find((item) => item.id === template).content
    );
}

export default BoarMainTemplate;
