import Api from './Api'
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    auth(data) {
        return Api().post('/locations/board', data)
    },
    sendCashierReadyOrder(id) {
        const boardToken = localStorage.getItem('boardToken')
        return Api().post(`/order/cashier/ready/${id}`, {},{
            headers: {
                "Authorization-Board": `Bearer ${boardToken}`
            }
        })
    },
    sendCashierDeleteOrder(id) {
        const boardToken = localStorage.getItem('boardToken')
        return Api().delete(`/order/cashier/${id}`, {
            headers: {
                "Authorization-Board": `Bearer ${boardToken}`
            }
        })
    },
    getOrders() {
        const boardToken = localStorage.getItem('boardToken')
        return Api().get('/order', {
            headers: {
                "Authorization-Board": `Bearer ${boardToken}`
            }
        })
    },
    getBorderCompany() {
        const boardToken = localStorage.getItem('boardToken')
        return Api().get('/locations/board/company', {
            headers: {
                "Authorization-Board": `Bearer ${boardToken}`
            }
        })
    }
}