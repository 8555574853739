import React from 'react';
import FullLogo from '../assets/images/fullLogo.svg';
import ArrowLeft from '../assets/images/arrow_left.svg';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Footer from "../components/landing/Footer";

function Instruction() {
    const { t } = useTranslation();

    return (
        <div className='privacy-policy'>
            <div className="privacy-policy__head">
                <div className="privacy-policy__head__content">
                    <div className="privacy-policy__head__content__logo">
                        <img src={FullLogo} alt="logo"/>
                    </div>
                </div>
            </div>
            <div className="privacy-policy__body">
                <div className="privacy-policy__body__content">
                    <div className="privacy-policy__body__content__head">
                        <Link to={'/'} className="privacy-policy__body__content__head__btn">
                            <img src={ArrowLeft} alt="left"/>
                            {t('instruction.back')}
                        </Link>
                    </div>
                    <div className="privacy-policy__body__content__title">
                        {t('instruction.body.settings.title')}
                    </div>
                    <div className="privacy-policy__body__content__subtitle">
                        {t('instruction.body.settings.description')}
                    </div>
                    <div className="privacy-policy__body__content__text">
                        <ol>
                            <li>
                                {t('instruction.body.settings.content.one')}
                            </li>
                            <li>
                                {t('instruction.body.settings.content.two')} <a href="https://dntb.in/b">https://dntb.in/b</a>
                            </li>
                            <li dangerouslySetInnerHTML={{ __html: t('instruction.body.settings.content.three') }}></li>
                            <li>
                                {t('instruction.body.settings.content.four')}
                            </li>
                            <li>
                                {t('instruction.body.settings.content.five')}
                            </li>
                        </ol>
                    </div>

                    <div className="privacy-policy__body__content__video">
                        <div className="privacy-policy__body__content__video__title">
                            {t('instruction.body.settings.link')} <a href="https://www.youtube.com/watch?v=gBHq10fCeb8&ab_channel=DineInTime" target={'_blank'} rel="noreferrer">https://www.youtube.com/embed/ktPjNf1F7as?si=3AD1odPeBiHQVERQ</a>
                        </div>
                    </div>

                    <div className="privacy-policy__body__content__subtitle">
                        {t('instruction.body.settings.additional_monitor.title')}
                    </div>
                    <div className="privacy-policy__body__content__text">
                        <p>{t('instruction.body.settings.additional_monitor.description')}</p>
                        <ol>
                            <li>
                                {t('instruction.body.settings.additional_monitor.content.one')}
                            </li>
                            <li>
                                {t('instruction.body.settings.additional_monitor.content.two')}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Instruction;
