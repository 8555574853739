import React from 'react';
import FullLogo from '../assets/images/fullLogo.svg';
import ArrowLeft from '../assets/images/arrow_left.svg';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Footer from "../components/landing/Footer";

function PrivacyPolicy() {
    const { t } = useTranslation();

    return (
        <div className='privacy-policy'>
            <div className="privacy-policy__head">
                <div className="privacy-policy__head__content">
                    <div className="privacy-policy__head__content__logo">
                        <img src={FullLogo} alt="logo"/>
                    </div>
                </div>
            </div>
            <div className="privacy-policy__body">
                <div className="privacy-policy__body__content">
                    <div className="privacy-policy__body__content__head">
                        <Link to={'/'} className="privacy-policy__body__content__head__btn">
                            <img src={ArrowLeft} alt="left"/>
                            {t('instruction.back')}
                        </Link>
                    </div>
                    <div className="privacy-policy__body__content__title">
                        {t('privacy-policy.title')}
                    </div>
                    <div className="privacy-policy__body__content__text">
                        <ul>
                            {Array.isArray(t('privacy-policy.content', { returnObjects: true })) ? t('privacy-policy.content', { returnObjects: true }).map((item, index) => (
                                <li key={index}>{item}</li>
                            )) : null}
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
