import React from 'react';
import LanguageSelect from "./LanguageSelect";
import FullLogoWhite from "../../assets/images/fullLogoWhite.svg";
import {Link} from "react-router-dom";
import Mail from "../../assets/images/home/mail.svg";
import Telegram from "../../assets/images/home/telegram.svg";
import {useTranslation} from "react-i18next";
import CurrencySelect from "./CurrencySelect";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <div className="home-page__footer">
            <span className="home-page__footer__currency">
                 <CurrencySelect />
            </span>
            <span className="home-page__footer__language">
                 <LanguageSelect/>
            </span>
            <div className="home-page__footer__content">
                <div className="home-page__footer__content__logo">
                    <img src={FullLogoWhite} alt="" className="home-page__footer__content__logo"/>
                </div>
                <div className="home-page__footer__content__text">
                    <div className="home-page__footer__content__text__links">
                        <div className="home-page__footer__content__text__links__link">
                            <Link to="/privacy-policy">{t('home-page.footer.policy')}</Link>
                        </div>
                        <div className="home-page__footer__content__text__links__link">
                            <a href="https://joinposter.com/ua/applications/dineintime">{t('home-page.footer.shop')}</a>
                        </div>
                        <div className="home-page__footer__content__text__links__link">
                            <a href="/instruction">{t('home-page.footer.instruction')}</a>
                        </div>
                        <div className="home-page__footer__content__text__links__link">
                            <a href="https://joinposter.com/ua">Poster POS</a>
                        </div>
                    </div>
                    <div className="home-page__footer__content__text__contacts">
                        <div
                            className="home-page__footer__content__text__contacts__title">{t('home-page.footer.contacts')}</div>
                        <div className="home-page__footer__content__text__contacts__item">
                            <img src={Mail} alt="mail"/>
                            <a href="mailto:support@dineintime.org">support@dineintime.org</a>
                        </div>
                        <div className="home-page__footer__content__text__contacts__item">
                            <img src={Telegram} alt="telegram"/>
                            <a href="https://t.me/dineintime">@dineintime</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
