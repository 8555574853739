import React, {useEffect, useRef} from 'react';
import ArrowDown from '../../assets/images/arrow.svg';

function BaseSelect({defaultValue = null, options = [], onChange}) {

    const [value, setValue] = React.useState(defaultValue);
    const [open, setOpen] = React.useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if(onChange) onChange(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if(defaultValue) setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return (
        <div className="base-select" ref={ref}>
            <div className="base-select__main" onClick={() => {setOpen(!open)}}>
                <div className="base-select__main__value">{value.text}</div>
                <div className="base-select__main__arrow">
                    <img src={ArrowDown} alt="arrow-down"/>
                </div>
            </div>
            {open && <div className="base-select__options">
                {options.map((option, index) => {
                    return (
                        <div key={index} className={`base-select__options__option ${value.value === option.value ? 'base-select__options__option__selected' : ''}`} onClick={() => {setValue(option); setOpen(false)}}>{option.text}</div>
                    )
                })}
            </div>}
        </div>
    );
}

export default BaseSelect;
