import React, {useEffect} from 'react';
import BaseButton from "../../components/Main/BaseButton";
import {fetchAuthAdmin} from "../../store/authSlice";
import {
    fetchGetCompanies,
    fetchUpdateCompaniesPlanInfo
} from "../../store/companySlice";
import {useDispatch, useSelector} from "react-redux";
import {fetchGetPlan} from "../../store/plansSlice";
import moment from "moment";
import BaseToggle from "../../components/Main/BaseToggle";

function AdminList() {
    const companies = useSelector((state) => state.company.companies)
    const dispatch = useDispatch();
    const [token, setToken] = React.useState(null);
    const [login, setLogin] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState(false);
    const [companiesValues, setCompaniesValues] = React.useState([]);


    useEffect(() => {
        if (companies.length){
            setCompaniesValues(JSON.parse(JSON.stringify(companies)));
        }
    }, [companies]);

    const onAuth = () => {
        dispatch(fetchAuthAdmin({login: login, password: password})).unwrap().then((data) => {
            setToken(data.access_token);
            localStorage.setItem('adminToken', data.access_token);
            setTimeout(() => {
                dispatch(fetchGetCompanies());
                dispatch(fetchGetPlan());
            }, 100)
        }).catch(() => {
            setError(true);
        });
    }

    // useEffect(() => {
    //     if(user && user.company && user.company.location){
    //         setLocations(user.company.location.map((item, index) => {
    //             return {
    //                 ...item,
    //                 checked: !!item.expireSubscribe
    //             }
    //         }));
    //     }
    // }, [user]);

    const onSave = () => {
        console.log(companiesValues, 'companiesValues');
        dispatch(fetchUpdateCompaniesPlanInfo({companies: companiesValues}));
    }

    return (
        <div>
            <div className="admin">
                { token === null ? (
                    <div className="admin__auth">
                        <div className="admin__auth__fields">
                            <input
                                type="text"
                                className="admin__auth__fields__field"
                                placeholder="Логін"
                                onInput={(e) => {setLogin(e.target.value); setError(false)}}/>
                            <input
                                type="password"
                                className="admin__auth__fields__field"
                                placeholder="Пароль"
                                onInput={(e) => {setPassword(e.target.value); setError(false)}}
                            />
                        </div>
                            <div className="admin__auth__error" style={{opacity: +error}}>
                                Помилка авторизації
                            </div>
                        <div className="admin__auth__footer">
                            <span onClick={() => {onAuth()}}>
                                <BaseButton text={"Увійти"}/>
                            </span>
                        </div>
                    </div>
                ) : null }
                {
                    token !== null ? (
                        <div className="admin__list">
                            <div className="admin__list__body">
                                <table className="admin__list__body__table">
                                    <thead>
                                    <tr>
                                        <th>Компанія</th>
                                        <th>Пошта</th>
                                        <th>Тариф</th>
                                        <th>Кінець підписки</th>
                                        <th>Null</th>
                                        <th>Доступно закладів</th>
                                        <th>Язык</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        companiesValues.map((item, index) => (
                                            <tr key={item.id}>
                                                <td className="admin__list__body__table__value">{item.name}</td>
                                                <td className="admin__list__body__table__value">{item.user[0].email}</td>
                                                <td className="admin__list__body__table__value">
                                                    {item.plan ? item.plan.name : 'Без тарифу'}
                                                </td>
                                                <td className="admin__list__body__table__value">
                                                    {moment(item.expireSubscribe).format('DD/MM/YYYY')}
                                                </td>
                                                <td className="admin__list__body__table__value">
                                                    <BaseToggle
                                                        checked={item?.show_additional_plans}
                                                        onChange={(data) => {
                                                            const newCompanies = [...companiesValues];
                                                            newCompanies[index].show_additional_plans = data;
                                                            setCompaniesValues(newCompanies);
                                                        }}
                                                    />
                                                </td>
                                                <td className="admin__list__body__table__value">
                                                    <input
                                                        min={0}
                                                        type="number"
                                                        className="admin__list__body__table__value__field"
                                                        value={item.location_count}
                                                        onInput={(e) => {
                                                            const newCompanies = [...companiesValues];
                                                            newCompanies[index].location_count = e.target.value;
                                                            setCompaniesValues(newCompanies);
                                                        }}
                                                    />
                                                </td>
                                                <td className="admin__list__body__table__value">
                                                    <input
                                                        min={0}
                                                        type="text"
                                                        className="admin__list__body__table__value__field"
                                                        value={item.user[0].lang}
                                                        onInput={(e) => {
                                                            const newCompanies = [...companiesValues];
                                                            newCompanies[index].user_lang = e.target.value;
                                                            newCompanies[index].user[0].lang = e.target.value;
                                                            setCompaniesValues(newCompanies);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="admin__list__footer">
                                <span onClick={onSave}>
                                    <BaseButton text={"Зберегти"}/>
                                </span>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
}

export default AdminList;
